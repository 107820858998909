import React, { createContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import useLocation from 'hooks/useLocation';
import scrollToSection from 'utils/scrollToSection';
import emitMatomoEvent, { eventsNames } from 'utils/emitMatomoEvent';

export const NavigationContext = createContext();

const NavigationProvider = ({ children }) => {
  const [favsLength, setFavsLength] = useState(0);
  const [isSidenavVisible, setIsSidenavVisible] = useState(false);
  const [isNavHidden, setIsNavHidden] = useState(true);
  const [scrollSection, setScrollSection] = useState(null);
  const [initialSearchParam, setInitialSearchParam] = useState('');
  const [isPromotionDrawerVisible, setIsPromotionDrawerVisible] =
    useState(false);
  const [savedBrowserHistory, setSavedBrowserHistory] = useState(null);
  const location = useLocation();

  useEffect(() => {
    if (scrollSection) scrollToSection(scrollSection);
    setIsNavHidden(true);
  }, [location]);

  let prevScroll = 0;

  const handleScroll = e => {
    const window = e.currentTarget;

    if (prevScroll > window.scrollY && window.scrollY >= 200) {
      setIsNavHidden(false);
    } else if (window.scrollY >= 0) {
      setIsNavHidden(true);
    }
    prevScroll = window.scrollY;
  };

  useEffect(() => {
    window.addEventListener('scroll', e => handleScroll(e));

    return () => {
      window.removeEventListener('scroll', e => handleScroll(e));
    };
  }, []);

  useEffect(() => {
    const isBrokenGoogleLink = window.location.hash.includes('?mtm_source');

    if (isBrokenGoogleLink) {
      const indexOfQuestionMark = window.location.hash.indexOf('?');
      const anchorSubstring = window.location.hash.substring(
        0,
        indexOfQuestionMark
      );
      const mtmSubstring = window.location.hash
        .substring(indexOfQuestionMark)
        .replace('?', '&');

      const fixedInitialSearchParam = `${window.location.search}${mtmSubstring}`;
      setInitialSearchParam(fixedInitialSearchParam);

      setTimeout(() => {
        scrollToSection(anchorSubstring, 'instant');
      }, 1);
      return;
    }

    setInitialSearchParam(window.location.search);

    const indexOfHashMark = window.location.hash.indexOf('#');
    if (indexOfHashMark === -1) return;
    const anchorSubstring = window.location.hash.substring(indexOfHashMark);
    setTimeout(() => {
      scrollToSection(anchorSubstring, 'instant');
    }, 1);
  }, []);

  const hideNav = force => {
    if (force) {
      setIsNavHidden(true);
      return;
    }
    if (window.scrollY >= 100) setIsNavHidden(true);
  };

  const closeSidenav = () => {
    if (isSidenavVisible) {
      setIsSidenavVisible(false);
      setTimeout(hideNav, 800);
      document.body.style.overflow = 'auto';
    }
  };

  const openSidenav = () => {
    if (!isSidenavVisible) {
      setIsSidenavVisible(true);
      document.body.style.overflow = 'hidden';
    }
  };

  const handleClose = section => {
    setTimeout(hideNav, 800);
    setScrollSection(section);
    setScrollSection(null);
  };

  const openPromotionDrawer = () => {
    setIsPromotionDrawerVisible(true);
    setIsNavHidden(true);
    document.body.style.overflow = 'hidden';
    document.getElementsByTagName('html')[0].style.overflow = 'hidden';

    if (!isPromotionDrawerVisible) {
      emitMatomoEvent({
        event: eventsNames.click_promocja_otwarcie,
      });
    }
  };
  const closePromotionDrawer = noMatomoEvent => {
    const formOverlayVisible = document.querySelector('.form-overlay-visible');

    if (formOverlayVisible) {
      return;
    }

    setIsPromotionDrawerVisible(false);
    document.body.style.overflow = 'auto';
    document.getElementsByTagName('html')[0].style.overflow = 'auto';

    if (noMatomoEvent) {
      return;
    }

    if (isPromotionDrawerVisible) {
      emitMatomoEvent({
        event: eventsNames.click_promocja_zamkniecie,
      });
    }
  };

  const saveBrowserHistory = history => {
    setSavedBrowserHistory(history);
  };

  return (
    <NavigationContext.Provider
      value={{
        isSidenavVisible,
        handleClose,
        closeSidenav,
        isNavHidden,
        openSidenav,
        hideNav,
        favsLength,
        setFavsLength,
        initialSearchParam,
        isPromotionDrawerVisible,
        closePromotionDrawer,
        openPromotionDrawer,
        saveBrowserHistory,
        savedBrowserHistory,
      }}
    >
      {children}
    </NavigationContext.Provider>
  );
};

NavigationProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default NavigationProvider;
